import { datadogRum } from '@datadog/browser-rum'
import { FeatureFlagProvider, LDProvider, MockProvider } from '@touchbistro/react-feature-flags'
import browserConfig from '~/src/shared/config/browser'

export const LD_FLAG_RESET_RMM = 'vaf.reset-rmm'
export const LD_FLAG_CLOUD_FLOORPLAN_V1 = 'cloud.floorplan-v1'
export const LD_FLAG_SHOW_EMPTY_FLOORPLAN = 'ark.show-empty-floorplan'
export const LD_FLAG_CLOUD_FLOORPLAN_CASH_REGISTER_AUTO_NUMBERING =
  'cloud.floorplan-cash-register-auto-numbering'
export const LD_FLAG_CLOUD_FLOORPLAN_USER_FRIENDLY_ERROR = 'cloud.floorplan-user-friendly-error'
export const LD_FLAG_DOWNTIME_BANNER = 'cloud-downtime-banner'
/** @deprecated */
export const LD_FLAG_PROMOTIONS = 'vaf.promotions'
export const LD_FLAG_DEVICE_LINKING = 'vaf.device-link-menu'
export const LD_FLAG_POST_ACTIVATION_LANDING_PAGE = 'ark.post-activation-landing-page'

// Invenue Staff
export const LD_SLF_CUSTOM_ROLES = 'slf.custom-roles'
export const LD_EXPORT_STAFF = 'ark.export-staff'
export const LD_FLAG_OO_DEV = 'oo.dev'

export const LD_FLAG_REPORT_SHOW_BACK_BUTTON_CUSTOM_REPORTS = 'ark.add-back-button-scheduled-report'
export const LD_FLAG_SHOW_SERVER_READ_REPORT = 'ark.show-server-read-report'

export const LD_FLAG_ALL_USER_ACCESS_IMPORT_EXPORT = 'vcs.enable-all-user-access-import-export'
export const LD_FLAG_STATIC_CLOUD_FLOORPLAN_ENHANCEMENTS =
  'ark.static-cloud-flooplan-enhancements-june2022'
export const LD_FLAG_CLOUD_FLOORPLAN_TOUCH_CONTROL = 'ark.res-floorplan-touch-control'
export const LD_FLAG_CLOUD_FLOORPLAN_REWORK_DRAG_MULTI_SELECT =
  'ark.res-floorplan-rework-drag-multi-select'

// MARKETPLACE - IMPORTED FROM VAF
export const LD_FLAG_AUTO_ONBOARDING_LOYALTY = 'vcs.lylty-auto-onboarding'

export const LD_FLAG_DOORDASH_FIELD = 'ark.show-door-dash-drive-for-bills-report'
export const LD_FLAG_MARGINEDGE_OLD_USERS = 'ark.margine-edge-old-flow-users'
export const LD_FLAG_PARTNER_OVERRIDES = 'papis.partner-overrides'
export const LD_FLAG_PAPIS_PEACHWORKS = 'papis.peachworks'
export const LD_FLAG_PAPIS_AUTO_ENABLED_VENUE_INTEGRATION = 'papis-auto-enable-venue-integration'

// RMM3 - Menu Management
export const LD_FLAG_HIDE_PLU_RMM3 = 'ark.hide-plu-rmm3'
export const LD_FLAG_POS_DEMO = 'ark.menu-preview-pos'
export const LD_FLAG_SHOW_UPGRADE_USER_FLOW_2023_Q2 = 'slf.upgrade-user-flow-2023-q2'
export const LD_FLAG_RMM3_COURSING = 'ark.rmm3-coursing'
export const LD_FLAG_RMM3_MENU_ITEM_SCHEDULING = 'ark.rmm3-menu-item-scheduling'
export const LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL = 'ark.rmm3-menu-item-is-alcohol'
export const LD_FLAG_RMM3_QR_CODES = 'ark.rmm3-qr-codes'
export const LD_FLAG_RMM3_MENU_ITEM_BULK_UPDATE_PREP_STATION =
  'ark.rmm3-menu-item-bulk-update-prep-station'
export const LD_FLAG_RMM3_UPC = 'ark.rmm3-upc'
export const LD_FLAG_RMM3_ALLOW_RETURNS = 'ark.rmm3-allow-returns'
export const LD_FLAG_RMM3_EXTRA_ITEM_FILTERS = 'ark.rmm3-extra-item-filters'
export const LD_FLAG_ENABLE_ADVANCED_DISCOUNT = 'cloud.enable-advanced-discount'
export const LD_FLAG_RMM3_STOCK_AVAILABILITY = 'ark.rmm3-stock-availability'

// This feature flag represents enabling ALL new group related behaviour on the frontend and backend
// NOTE: I know this is confusing because it has 'selector' in the name, but the naming can't be changed
// without porting over the targeted users in LD to a different flag, and that's a lot of work just to
// change the name
export const LD_FLAG_VENUE_GROUPING_SELECTOR = 'ark.base-selector-venue-grouping'

// This feature flag represents EXCLUSIVELY displaying the new version of the single and multi base selector
// without any of the other functionality that comes with enabling venue groups
export const LD_FLAG_NEW_SELECTORS = 'ark.new-selectors'

// #region Cash Management
export const LD_FLAG_CASH_MANAGEMENT_CLOUD = 'cloud.cash-management'
// #endregion

export const LD_POS_ADMIN_POLICY_ASSIGNMENT = 'ark.pos-admin-policy-assignment'

// Android POS
export const LD_FLAG_ANDROID_PREP_STATIONS = 'tb-android.prep-stations'
export const LD_FLAG_ANDROID_DOORDASH_MARKETPLACE_INTEGRATION =
  'tb-android.dd-marketplace-integration'

// _______________PAYMENTS______________
// Payment Settings
export const LD_FLAG_PAYMENT_SETTINGS = 'ark.payment-settings'
export const LD_FLAG_PAYMENT_SETTINGS_BATCH_CLOSE_SETTINGS =
  'ark.payment-settings.batch-close-settings'

// Receipt Settings
export const LD_FLAG_RECEIPT_SETTINGS_IOS = 'ark.receipt-settings-ios'
// Terminal Management
export const LD_FLAG_TERMINAL_MANAGEMENT = 'ark.terminal_management'
// _______________PAYMENTS______________

// New Cloud CRM
export const LD_FLAG_GUEST_CRM = 'cloud.guest-crm'

// Removed menu item
export const LD_FLAG_GE_PROVISIONING = 'ge-provisioning-inaccessible'
// Service Charges
export const LD_FLAG_SERVICE_CHARGES = 'venue-info.service-fees'
// Reports Payments
export const LD_FLAG_PURCHASES = 'pay.reports.purchases.enabled'
export const LD_FLAG_DEPOSITS = 'pay.reports.deposits.enabled'

// Android Labour
export const LD_FLAG_ANDROID_LABOUR = 'tb-android.tb-labour'

// Bill Processor Entitlement
export const LD_FLAG_BILL_PROCESSOR_AGENT_FORM = 'ark.bill-processor-entitlement-form'

let provider: FeatureFlagProvider

if (browserConfig.ldClientId !== '') {
  provider = new LDProvider(browserConfig.ldClientId, {
    fetchGoals: false,
    streaming: false,
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key: string, detail: { value: unknown }) => {
          datadogRum.addFeatureFlagEvaluation(key.replaceAll('.', '_'), detail.value)
        },
      },
    ],
  })
} else {
  const reservationsBaseXRefIDs = [
    50000, 50001, 50002, 50003, 50004, 50005, 50006, 50007, 50008, 50009, 50010, 50011, 50012,
    50013, 50014, 50015, 50016, 50017,
  ]

  const reservationsFlags = reservationsBaseXRefIDs.reduce(
    (mockFlags, XRefID) => ({
      ...mockFlags,
      [`baseXRefID-${XRefID}`]: {
        flags: {
          [LD_FLAG_RESET_RMM]: true,
          [LD_FLAG_CLOUD_FLOORPLAN_V1]: true,
          [LD_FLAG_SHOW_EMPTY_FLOORPLAN]: true,
          [LD_FLAG_CLOUD_FLOORPLAN_CASH_REGISTER_AUTO_NUMBERING]: true,
          [LD_FLAG_CLOUD_FLOORPLAN_USER_FRIENDLY_ERROR]: true,
          [LD_FLAG_STATIC_CLOUD_FLOORPLAN_ENHANCEMENTS]: true,
          [LD_FLAG_CLOUD_FLOORPLAN_TOUCH_CONTROL]: true,
          [LD_FLAG_CLOUD_FLOORPLAN_REWORK_DRAG_MULTI_SELECT]: true,
          [LD_FLAG_PROMOTIONS]: true,
          [LD_FLAG_DEVICE_LINKING]: true,
          [LD_FLAG_SHOW_UPGRADE_USER_FLOW_2023_Q2]: true,
          [LD_FLAG_OO_DEV]: false,
          [LD_FLAG_POST_ACTIVATION_LANDING_PAGE]: true,
          [LD_FLAG_DOORDASH_FIELD]: true,
          [LD_FLAG_HIDE_PLU_RMM3]: true,
          [LD_FLAG_REPORT_SHOW_BACK_BUTTON_CUSTOM_REPORTS]: true,
          [LD_FLAG_AUTO_ONBOARDING_LOYALTY]: true,
          [LD_FLAG_SHOW_SERVER_READ_REPORT]: true,
          [LD_FLAG_POS_DEMO]: true,
          [LD_FLAG_RMM3_COURSING]: true,
          [LD_FLAG_MARGINEDGE_OLD_USERS]: true,
          [LD_FLAG_RMM3_MENU_ITEM_SCHEDULING]: true,
          [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
          [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
          [LD_FLAG_RMM3_MENU_ITEM_BULK_UPDATE_PREP_STATION]: true,
        },
      },
    }),
    {}
  )

  provider = new MockProvider({
    // tb-devmenu
    'baseXRefID-24477': {
      flags: {
        [LD_FLAG_RESET_RMM]: true,
        [LD_FLAG_CLOUD_FLOORPLAN_V1]: true,
        [LD_FLAG_SHOW_EMPTY_FLOORPLAN]: true,
        [LD_FLAG_CLOUD_FLOORPLAN_CASH_REGISTER_AUTO_NUMBERING]: true,
        [LD_FLAG_CLOUD_FLOORPLAN_USER_FRIENDLY_ERROR]: true,
        [LD_FLAG_STATIC_CLOUD_FLOORPLAN_ENHANCEMENTS]: true,
        [LD_FLAG_CLOUD_FLOORPLAN_TOUCH_CONTROL]: true,
        [LD_FLAG_CLOUD_FLOORPLAN_REWORK_DRAG_MULTI_SELECT]: true,
        [LD_FLAG_PROMOTIONS]: true,
        [LD_FLAG_DEVICE_LINKING]: true,
        [LD_FLAG_SHOW_UPGRADE_USER_FLOW_2023_Q2]: true,
        [LD_FLAG_OO_DEV]: false,
        [LD_FLAG_POST_ACTIVATION_LANDING_PAGE]: true,
        [LD_FLAG_DOORDASH_FIELD]: true,
        [LD_FLAG_HIDE_PLU_RMM3]: true,
        [LD_FLAG_REPORT_SHOW_BACK_BUTTON_CUSTOM_REPORTS]: true,
        [LD_FLAG_AUTO_ONBOARDING_LOYALTY]: true,
        [LD_FLAG_SHOW_SERVER_READ_REPORT]: true,
        [LD_FLAG_POS_DEMO]: true,
        [LD_FLAG_RMM3_COURSING]: true,
        [LD_FLAG_MARGINEDGE_OLD_USERS]: true,
        [LD_FLAG_RMM3_MENU_ITEM_SCHEDULING]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_RMM3_QR_CODES]: true,
        [LD_FLAG_RMM3_MENU_ITEM_BULK_UPDATE_PREP_STATION]: false,
        [LD_FLAG_ANDROID_PREP_STATIONS]: false,
        [LD_FLAG_CASH_MANAGEMENT_CLOUD]: true,
        [LD_SLF_CUSTOM_ROLES]: true,
        [LD_EXPORT_STAFF]: true,
        [LD_FLAG_PARTNER_OVERRIDES]: true,
        [LD_POS_ADMIN_POLICY_ASSIGNMENT]: true,
        [LD_FLAG_PAPIS_PEACHWORKS]: true,
        [LD_FLAG_RMM3_UPC]: true,
        [LD_FLAG_PAYMENT_SETTINGS]: true,
        [LD_FLAG_PAYMENT_SETTINGS_BATCH_CLOSE_SETTINGS]: true,
        [LD_FLAG_RMM3_ALLOW_RETURNS]: true,
        [LD_FLAG_PAPIS_AUTO_ENABLED_VENUE_INTEGRATION]: true,
        [LD_FLAG_GUEST_CRM]: true,
        [LD_FLAG_RMM3_EXTRA_ITEM_FILTERS]: true,
        [LD_FLAG_SERVICE_CHARGES]: true,
        [LD_FLAG_RECEIPT_SETTINGS_IOS]: true,
        [LD_FLAG_GE_PROVISIONING]: false,
        [LD_FLAG_ANDROID_LABOUR]: true,
        [LD_FLAG_BILL_PROCESSOR_AGENT_FORM]: true,
        [LD_FLAG_PURCHASES]: true,
        [LD_FLAG_DEPOSITS]: true,
        [LD_FLAG_ENABLE_ADVANCED_DISCOUNT]: true,
        [LD_FLAG_RMM3_STOCK_AVAILABILITY]: true,
      },
    },
    // Root of baseXRefID-24477
    'baseXRefID-be6ef922-6316-4048-1111-ff6595fb9fa1': {
      flags: {
        [LD_FLAG_RMM3_MENU_ITEM_SCHEDULING]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_RMM3_MENU_ITEM_BULK_UPDATE_PREP_STATION]: false,
        [LD_FLAG_RMM3_UPC]: true,
        [LD_FLAG_RMM3_ALLOW_RETURNS]: true,
        [LD_FLAG_RMM3_EXTRA_ITEM_FILTERS]: true,
        [LD_FLAG_RECEIPT_SETTINGS_IOS]: true,
      },
    },

    /** tb-devloyalty */
    // root
    'baseXRefID-20347776-6159-4d42-a123-d20222ea7557': {
      flags: {
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
        [LD_FLAG_CASH_MANAGEMENT_CLOUD]: true,
        [LD_SLF_CUSTOM_ROLES]: true,
      },
    },
    // group
    'baseXRefID-dd02d640-bdee-47e2-9ba5-c5cc6df2b17d': {
      flags: {
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
        [LD_FLAG_CASH_MANAGEMENT_CLOUD]: true,
        [LD_FLAG_GUEST_CRM]: true,
      },
    },
    'baseXRefID-b322b9d0-7f84-4071-a5c6-8c6051058b52': {
      flags: {
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
        [LD_FLAG_CASH_MANAGEMENT_CLOUD]: true,
        [LD_FLAG_GUEST_CRM]: true,
      },
    },
    // venue
    'baseXRefID-42144': {
      flags: {
        [LD_FLAG_OO_DEV]: true,
        [LD_FLAG_CASH_MANAGEMENT_CLOUD]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
        [LD_SLF_CUSTOM_ROLES]: true,
        [LD_FLAG_GE_PROVISIONING]: true,
      },
    },
    // venue
    'baseXRefID-42145': {
      flags: {
        [LD_FLAG_CASH_MANAGEMENT_CLOUD]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
      },
    },
    /** tb-devloyalty */

    // tb-devmenu
    'baseXRefID-MOCKDONALDS_TORONTO': {
      flags: {
        [LD_FLAG_HIDE_PLU_RMM3]: false,
        [LD_FLAG_RMM3_COURSING]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
        [LD_FLAG_RMM3_MENU_ITEM_SCHEDULING]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_RMM3_MENU_ITEM_BULK_UPDATE_PREP_STATION]: true,
        [LD_FLAG_RMM3_UPC]: true,
        [LD_FLAG_RMM3_ALLOW_RETURNS]: true,
        [LD_FLAG_RMM3_EXTRA_ITEM_FILTERS]: true,
        [LD_SLF_CUSTOM_ROLES]: true,
        [LD_FLAG_SHOW_UPGRADE_USER_FLOW_2023_Q2]: true,
        [LD_FLAG_ANDROID_DOORDASH_MARKETPLACE_INTEGRATION]: true,
        [LD_FLAG_RMM3_STOCK_AVAILABILITY]: true,
      },
    },
    'baseXRefID-MOCKDONALDS_NEW_YORK': {
      flags: {
        [LD_FLAG_HIDE_PLU_RMM3]: false,
        [LD_FLAG_RMM3_COURSING]: true,
        [LD_FLAG_RMM3_MENU_ITEM_SCHEDULING]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_RMM3_MENU_ITEM_BULK_UPDATE_PREP_STATION]: true,
        [LD_FLAG_RMM3_ALLOW_RETURNS]: true,
        [LD_FLAG_RMM3_EXTRA_ITEM_FILTERS]: true,
        [LD_FLAG_RMM3_STOCK_AVAILABILITY]: true,
      },
    },
    'baseXRefID-MOCKDONALDS_WINNIPEG': {
      flags: {
        [LD_SLF_CUSTOM_ROLES]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_RMM3_STOCK_AVAILABILITY]: true,
      },
    },
    'baseXRefID-MOCKDONALDS': {
      flags: {
        [LD_FLAG_HIDE_PLU_RMM3]: false,
        [LD_FLAG_RMM3_COURSING]: true,
        [LD_FLAG_RMM3_MENU_ITEM_SCHEDULING]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_RMM3_MENU_ITEM_BULK_UPDATE_PREP_STATION]: true,
        [LD_FLAG_RMM3_ALLOW_RETURNS]: true,
        [LD_FLAG_RMM3_EXTRA_ITEM_FILTERS]: true,
        [LD_FLAG_RMM3_STOCK_AVAILABILITY]: true,
      },
    },
    'baseXRefID-anonymous': {
      flags: {
        [LD_FLAG_RESET_RMM]: true,
      },
    },
    // tb-devreport
    'baseXRefID-24478': {
      flags: {
        [LD_FLAG_RESET_RMM]: true,
        [LD_FLAG_OO_DEV]: true,
        [LD_FLAG_DEVICE_LINKING]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: false,
        [LD_FLAG_DOORDASH_FIELD]: true,
        [LD_FLAG_REPORT_SHOW_BACK_BUTTON_CUSTOM_REPORTS]: true,
        [LD_FLAG_SHOW_SERVER_READ_REPORT]: true,
        [LD_SLF_CUSTOM_ROLES]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_RMM3_QR_CODES]: true,
        [LD_FLAG_ANDROID_LABOUR]: true,
        [LD_POS_ADMIN_POLICY_ASSIGNMENT]: true,
      },
    },
    // tb-devmultiunit
    'baseXRefID-14628': {
      flags: {
        [LD_FLAG_RESET_RMM]: true,
        [LD_FLAG_CLOUD_FLOORPLAN_V1]: true,
        [LD_FLAG_CLOUD_FLOORPLAN_CASH_REGISTER_AUTO_NUMBERING]: true,
        [LD_FLAG_STATIC_CLOUD_FLOORPLAN_ENHANCEMENTS]: true,
        [LD_FLAG_CLOUD_FLOORPLAN_TOUCH_CONTROL]: true,
        [LD_FLAG_PROMOTIONS]: true,
        [LD_FLAG_DEVICE_LINKING]: true,
        [LD_FLAG_SHOW_UPGRADE_USER_FLOW_2023_Q2]: true,
        [LD_FLAG_OO_DEV]: true,
        [LD_FLAG_POST_ACTIVATION_LANDING_PAGE]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: false,
        [LD_FLAG_DOORDASH_FIELD]: true,
        [LD_FLAG_SHOW_SERVER_READ_REPORT]: true,
        [LD_SLF_CUSTOM_ROLES]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
      },
    },
    // Legacy venue with un-migrated floorplans hides floorplan options
    'baseXRefID-25556': {
      flags: {
        [LD_FLAG_SHOW_EMPTY_FLOORPLAN]: false,
        [LD_FLAG_CLOUD_FLOORPLAN_V1]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
      },
    },
    // Mock venue used with flags needed for menu navigation tests
    'baseXRefID-menuNavTestBaseXRefID1': {
      flags: {
        [LD_FLAG_PROMOTIONS]: true,
        [LD_FLAG_RESET_RMM]: true,
        [LD_FLAG_ALL_USER_ACCESS_IMPORT_EXPORT]: true,
        [LD_FLAG_DEVICE_LINKING]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_RMM3_STOCK_AVAILABILITY]: true,
      },
    },
    'baseXRefID-MOCKDONALDS_CALGARY': {
      flags: {
        [LD_SLF_CUSTOM_ROLES]: true,
        [LD_FLAG_SHOW_UPGRADE_USER_FLOW_2023_Q2]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
        [LD_FLAG_SHOW_SERVER_READ_REPORT]: true,
        [LD_FLAG_RMM3_MENU_ITEM_IS_ALCOHOL]: true,
        [LD_FLAG_CASH_MANAGEMENT_CLOUD]: true,
        [LD_FLAG_RMM3_STOCK_AVAILABILITY]: true,
      },
    },
    'baseXRefID-048e3f50-51c9-4c2d-809a-0993e01d30da': {
      flags: {
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
      },
    },
    'baseXRefID-ec0bd2ff-3e53-4ddf-b03b-b326642b07e4': {
      flags: {
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
      },
    },
    'baseXRefID-groupNavTestBaseXRefID1': {
      flags: {
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
      },
    },
    'baseXRefID-groupNavTestBaseXRefID2': {
      flags: {
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
      },
    },
    'baseXRefID-b767b012-f53b-4eb4-8955-bc8e4234de4e': {
      flags: {
        [LD_FLAG_CLOUD_FLOORPLAN_V1]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
        [LD_FLAG_RMM3_UPC]: true,
        [LD_FLAG_PAYMENT_SETTINGS]: true,
        [LD_FLAG_RMM3_ALLOW_RETURNS]: true,
        [LD_FLAG_RMM3_EXTRA_ITEM_FILTERS]: true,
        [LD_FLAG_RECEIPT_SETTINGS_IOS]: true,
        [LD_FLAG_ENABLE_ADVANCED_DISCOUNT]: true,
      },
    },
    'baseXRefID-newSelectorFFTestingBase': {
      flags: {
        [LD_FLAG_NEW_SELECTORS]: true,
      },
    },

    /** Payments TEAM */
    'baseXRefID-Cloud-9-CorporationXRefID': {
      flags: {
        [LD_FLAG_NEW_SELECTORS]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
        [LD_FLAG_TERMINAL_MANAGEMENT]: true,
      },
    },
    'baseXRefID-Payrix': {
      flags: {
        [LD_FLAG_TERMINAL_MANAGEMENT]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
      },
    },
    // Payment Settings - Batch Close Feature Flag Test
    'baseXRefID-60000': {
      flags: {
        [LD_FLAG_PAYMENT_SETTINGS]: true,
        [LD_FLAG_PAYMENT_SETTINGS_BATCH_CLOSE_SETTINGS]: true,
        [LD_FLAG_NEW_SELECTORS]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
      },
    },
    'baseXRefID-60001': {
      flags: {
        [LD_FLAG_PAYMENT_SETTINGS]: true,
        [LD_FLAG_PAYMENT_SETTINGS_BATCH_CLOSE_SETTINGS]: false,
        [LD_FLAG_NEW_SELECTORS]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
      },
    },
    'baseXRefID-60002': {
      flags: {
        [LD_FLAG_NEW_SELECTORS]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
        [LD_FLAG_TERMINAL_MANAGEMENT]: true,
      },
    },
    'baseXRefID-60003': {
      flags: {
        [LD_FLAG_NEW_SELECTORS]: true,
        [LD_FLAG_VENUE_GROUPING_SELECTOR]: true,
      },
    },

    /** tb-devandroid */
    // root
    'baseXRefID-43e99108-3b36-4eb7-9455-6c28e4377e88': {
      flags: {
        [LD_SLF_CUSTOM_ROLES]: true,
      },
    },
    // group
    'baseXRefID-82abc787-f7c0-4fde-8088-ceec5d1a6dcd': {
      flags: {
        [LD_SLF_CUSTOM_ROLES]: true,
      },
    },
    // venue
    'baseXRefID-70002': {
      flags: {
        [LD_FLAG_OO_DEV]: true,
        [LD_SLF_CUSTOM_ROLES]: true,
        [LD_FLAG_ANDROID_DOORDASH_MARKETPLACE_INTEGRATION]: true,
        [LD_FLAG_ANDROID_PREP_STATIONS]: true,
        [LD_FLAG_ANDROID_LABOUR]: true,
      },
    },
    /** tb-devandroid */

    // Agent User
    'userXRefID-8b4a29f4-6836-4965-9bb6-926ad702df65': {
      flags: {
        [LD_FLAG_GE_PROVISIONING]: true,
      },
    },

    // Reservations
    ...reservationsFlags,
  })
}

export const FeatureFlagClient = provider
